import { useEffect } from 'react';

//? ---- components ----
import {
    Container,
    CssBaseline
} from '@mui/material';
import Lottie from 'react-lottie';
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- utils ----
import { useNavigate, useParams } from 'react-router-dom';
import { getStorage, removeStorage } from '../utils/storage';
import { signIn, signInFederate } from "../apis";
import { API_ERROR_CODE } from "../data/dataCenter";
import { KAKAO_PARAMS } from '../config';
import { getParams } from '../utils/dataUtils';
import queryString from 'query-string';
import useLocales from '../hooks/useLocales';
import axios from 'axios';
import * as animationData from '../assets/anim/loading.json'

export default function Callback() {
    const navigate = useNavigate();
    const { provider }: any = useParams();
    const { translate } = useLocales();

    useEffect(() => {
        const execute = async () => {
            const {
                redirect_uri,
                response_type,
                client_id,
                state,
                scope,
            } = getParams();
            if (redirect_uri == undefined ||
                response_type == undefined ||
                client_id == undefined) {
                alert(translate("action.ACTION_INVALID"));
                return;
            }
            switch (provider) {
                case 'email': {
                    const params: any = queryString.parse(window.location.search);
                    const { authorization, email } = params;
                    handleLoginEmail({ authorization, email });
                    break;
                }
                case 'kakao': {
                    const params: any = queryString.parse(window.location.search);
                    const { code, token, error } = params;
                    if (error != undefined) {
                        navigate(`/`);
                        break;
                    }
                    let res;
                    var access_token = token;
                    if (!access_token) {
                        const url_token = "https://kauth.kakao.com/oauth/token";
                        const payload = `grant_type=authorization_code&client_id=${KAKAO_PARAMS.CLIENT_ID}&code=${code}&redirect_uri=${KAKAO_PARAMS.REDIRECT_URL}`;
                        res = await axios.post(url_token, payload, { headers: { "Content-Type": "application/x-www-form-urlencoded", } });
                        access_token = res.data.access_token;
                    }
                    handleLogin({ provider: "kakao", token: access_token });
                    break;
                }
                case 'google': {
                    var search: string = window.location.search;
                    if ((window.location.href).split('#')[1]) {
                        search = `?${(window.location.href).split('#')[1]}`;
                    }
                    const params: any = queryString.parse(`${search}`);
                    const token = params.id_token != undefined ? params.id_token : params.token;
                    handleLogin({ provider: "google", token });
                    break;
                }
                case 'apple': {
                    var search: string = window.location.search;
                    if ((window.location.href).split('#')[1]) {
                        search = `?${(window.location.href).split('#')[1]}`;
                    }
                    const params: any = queryString.parse(`${search}`);
                    const token = params.id_token != undefined ? params.id_token : params.token;
                    handleLogin({ provider: "apple", token });
                    break;
                }
            }
        }
        setTimeout(() => {
            execute();
        }, 1000);
    }, [])

    const handleLoginEmail = (data: { authorization: string, email: string }) => {
        const {
            redirect_uri,
            response_type,
            client_id,
            state,
            scope,
        } = getParams();
        const { authorization, email } = data;
        signIn({
            authorization,
            redirect_uri,
            response_type,
            client_id,
            state,
            scope,
        })
            .then((response: any) => {
                removeStorage("params")
                const searchParams = new URLSearchParams({
                    state,
                    ...response,
                });
                const redirect = `${redirect_uri}?${searchParams.toString()}`;
                window.location.href = redirect;
            })
            .catch((error) => {
                switch (error.code) {
                    case API_ERROR_CODE.AUTH_NOT_AUTHENTICATED: // 이메일 인증 화면 이동
                        {
                            const stringified = queryString.stringify({ email });
                            navigate(`/signupConfirm?${stringified}`);
                            return;
                        }
                }
                alert(error.message);
                window.history.back();
            })
            .finally(() => {

            })
    }

    const handleLogin = (data: { provider: string, token: string }) => {
        console.log('handleLogin: ', data);
        const {
            redirect_uri,
            response_type,
            client_id,
            state,
            scope,
        } = getParams();
        const { provider, token } = data;
        signInFederate({
            token,
            provider,
            redirect_uri,
            response_type,
            client_id,
            state,
            scope,
        })
            .then((response: any) => {
                removeStorage("params")
                const searchParams = new URLSearchParams({
                    state,
                    ...response,
                });
                const redirect = `${redirect_uri}?${searchParams.toString()}`;
                window.location.href = redirect;
            })
            .catch((error) => {
                var email = undefined;
                if (error.data) {
                    email = error.data.email;
                }
                switch (error.code) {
                    case API_ERROR_CODE.AUTH_NOT_AUTHENTICATED: // 이메일 인증 화면 이동
                        {
                            const stringified = queryString.stringify({ token, provider, email });
                            navigate(`/signupConfirm?${stringified}`);
                            return;
                        }
                    case API_ERROR_CODE.AUTH_NOT_EXiST: // 회원가입 화면 이동
                    case API_ERROR_CODE.AUTH_ERROR_DELETED:
                        {
                            const stringified = queryString.stringify({ token, provider, email });
                            navigate(`/signup?${stringified}`);
                            return;
                        }
                }
                alert(error.message);
                window.history.back();
            })
            .finally(() => {

            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MinibigLogo onClick={() => navigate("/")} />
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
            />
        </Container>
    );
}

