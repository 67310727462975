import React, { useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Box, Link, Typography, Container, useTheme } from '@mui/material';
import LanguagePopover from '../components/module/LanguagePopover';
import { getStorage, saveStorage } from '../utils/storage';
import queryString from 'query-string';
import useLocales from "../hooks/useLocales";
import ExtServiceInfo from "../components/organisms/ExtServiceInfo";

// ----------------------------------------------------------------------

export default function MainLayout() {
    const navigate = useNavigate();
    const { translate, onChangeLang } = useLocales();
    const { pathname } = useLocation();
    const { colors } = useTheme();
    const { from }: any = useParams();

    const [isValid, setValid] = useState(true);

    React.useEffect(() => {
        const params: any = queryString.parse(window.location.search);
        const {
            redirect_uri,
            response_type,
            client_id,
            state,
            scope,
            lang,
        } = params;
        if (lang != undefined) {
            onChangeLang(lang);
        }
        if (redirect_uri && response_type && client_id && state) {
            const data = {
                redirect_uri,
                response_type,
                client_id,
                state,
                scope,
            };
            saveStorage('params', JSON.stringify(data));
        }
        try {
            const authData = getStorage('params')!;
            const {
                redirect_uri,
                response_type,
                client_id,
                state,
                scope,
            } = JSON.parse(authData);
            if (redirect_uri == undefined ||
                response_type == undefined ||
                client_id == undefined ||
                state == undefined
            ) {
                setValid(false);
            }
        } catch (error) {
            setValid(false);
        }
    }, [])

    const renderServiceInfo = () => {
        return (
            <Box
                px={2}
                sx={{
                    position: "absolute",
                    bottom: 11,
                    left: 0,
                    right: 0,
                }}>
                <ExtServiceInfo serviceCode={from} />
            </Box>
        )
    };

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                height: "100vh",
                minHeight: { lg: 1 },
                backgroundColor: colors.background,

                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: "flex-end",
                }}
            >
                <LanguagePopover />
            </Box>
            <Box
                flex={1}
                paddingTop={3}>
                {
                    isValid ?
                        <Outlet /> :
                        <Typography
                            align="center"
                            sx={{
                                mt: 5
                            }}>
                            {translate("action.ACTION_INVALID")}
                        </Typography>
                }
            </Box>
            <Box>
                {
                    from &&
                    renderServiceInfo()
                }
            </Box>
            {
                pathname == "/" &&
                <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{
                        position: "absolute",
                        left: 0,
                        bottom: "10px",
                        right: 0,
                    }}
                >
                    {'Copyright © '}
                    <Link color="inherit" href="https://clomount.com/" target="_blank">
                        Clomount Co.,Ltd.
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'. '}
                </Typography>
            }
        </Container >
    );
}
