
export const saveStorage = (key: string, data: string) => {
    localStorage.setItem(key, data);
}

export const getStorage = (key: string) => {
    return localStorage.getItem(key);
}

export const removeStorage = (key: string) => {
    return localStorage.removeItem(key);
}