import { colorType } from "../@types/theme"

const colors = {
    primary: "#3C6CF7",
    delete: "#FF3C1D",
    white: "#FFFFFF",
    black: "#383838",
    textLight: "#E5E5E7",
    textDark: "#1C1C1E",
    secondary: '#83fe83',
    notification: '#FF453A',
}

export const colorsLight: colorType = {
    primary: colors.primary,
    secondary: '#EDF2FF',
    lightPrimary: '#F2F5FE',
    background: '#FBFBFB',
    card: colors.white,
    cardDark: '#EFEFEF',
    settingScreenDivider: '#EFEFEF',
    text: colors.textDark,
    textReverse: colors.white,
    textLight: colors.textLight,
    textDark: colors.textDark,
    subTitle: '#A4A2AA',
    inputTitle: '#5A5243',
    inputBackground: '#EFEFEF',
    border: '#EDEDED',
    iconColor: '#C7C7CC',
    deviceIconColor: '#717171',
    deviceIconDisabled: '#DBDBDB',
    deviceIconBackGround: "#F8F8F8",
    IrButtonColor: '#EEEEEE',
    IrButtonDisabled: '#F7F7F7',
    IrButtonIconColor: '#717171',
    IrButtonIconDisabled: '#DDDDDD',
    powerButtonColor: "#E03B2E",
    notification: colors.notification,
    delete: colors.delete,
    white: colors.white,
    black: colors.black,
    iosShadow: '#323232',
    androidShadow: '#C4C4C4',
    chipText: colors.primary,
}

export const colorsDark: colorType = {
    primary: colors.primary,
    secondary: '#0C234D',
    lightPrimary: '#0B1A23',
    background: '#010101',
    card: '#232323',
    cardDark: '#1D1D1D',
    settingScreenDivider: '#121212',
    text: colors.textLight,
    textReverse: colors.black,
    textLight: colors.textLight,
    textDark: colors.textDark,
    subTitle: '#5D5D5D',
    inputTitle: '#6C6C6C',
    inputBackground: '#303030',
    border: "#171717",
    iconColor: '#8E8E8E',
    deviceIconColor: '#C6C6C6',
    deviceIconDisabled: "#404040",
    deviceIconBackGround: "#191919",
    IrButtonColor: '#303030',
    IrButtonDisabled: '#191919',
    IrButtonIconColor: colors.textLight,
    IrButtonIconDisabled: '#303030',
    powerButtonColor: "#E03B2E",
    notification: colors.notification,
    delete: colors.delete,
    white: colors.white,
    black: colors.black,
    iosShadow: colors.white,
    androidShadow: colors.white,
    chipText: '#3572ED',
}
