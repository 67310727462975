import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { getStorage } from './storage';
import i18n from 'i18next';

export const checkKorean = () => {
    return (i18n.language == "ko");
}

export const base64Encode = (originText: string) => {
    return base64_encode(originText);
}

export const base64Decode = (base64EncodedText: string) => {
    return base64_decode(base64EncodedText);
}

export function regEmail(email: string) {
    const regEmail = /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9\w\.\_\-]+\.)+[a-zA-Z0-9]{2,8}$/i;

    return regEmail.test(email)
}

export function regPassword(password: string) {
    // const regPassword = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
    // return regPassword.test(password);
    return (password && password.length >= 8 && password.length <= 15);
}

export function getRandomSmallNumber() {
    return Math.floor(Math.random() * 999) + 1;
}

export const getParams = () => {
    const params = getStorage('params');
    if (!params) {
        return {};
    }
    const {
        redirect_uri,
        response_type,
        client_id,
        state,
        scope,
    } = JSON.parse(params);
    return {
        redirect_uri,
        response_type,
        client_id,
        state,
        scope,
    };
}