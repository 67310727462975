import { useRef, useState } from 'react';
// material
import { Box, MenuItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import MenuPopover from './MenuPopover';
import MIconButton from './MIconButton';
// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { allLang, currentLang, onChangeLang } = useLocales();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: "row",
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center">
                    {currentLang.label}
                </Typography>
                <MIconButton
                    ref={anchorRef}
                    onClick={() => setOpen(true)}
                    sx={{
                        padding: 0,
                        width: 44,
                        height: 44,
                        ...(open && { bgcolor: 'action.selected' })
                    }}
                >
                    <img src={currentLang.icon} alt={currentLang.label} style={{ width: "28px", borderRadius : "3px" }} />
                </MIconButton>
            </Box>
            <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
                <Box sx={{ py: 1 }}>
                    {allLang.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === currentLang.value}
                            onClick={() => {
                                onChangeLang(option.value);
                                setOpen(false);
                            }}
                            sx={{ py: 1, px: 2.5 }}
                        >
                            <ListItemIcon>
                                <Box component="img" alt={option.label} src={option.icon} style={{ width: "28px", borderRadius : "3px" }} />
                            </ListItemIcon>

                            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                                {option.label}
                            </ListItemText>
                        </MenuItem>
                    ))}
                </Box>
            </MenuPopover>
        </>
    );
}
