import { Box, SxProps, Typography } from "@mui/material";
import useLocales from "../../hooks/useLocales";


type Props = {
    serviceCode?: string;
    sx?: SxProps;
};

const ExtServiceInfo = (props: Props) => {
    const { translate } = useLocales();

    const { serviceCode, sx } = props;

    const getServiceLogoPath = () => {
        switch (serviceCode) {
            case 'gh':
                return "/img/ext_logo_gh.png"
            case 'st':
                return "/img/ext_logo_st.png"
            case 'clova':
                return "/img/ext_logo_clova.png"
            case 'kt':
                return "/img/ext_logo_kt.png"
        }
        return undefined;
    }

    if (!getServiceLogoPath()) return <></>;

    return (
        <Box
            sx={{
                px: 1,
                mb: 6,
                display: "flex",
                height: "56px",
                border: "1px solid #949494",
                borderRadius: "100vmax",
                alignItems: "center",
                justifyContent: "center",
                gap: 3.5,
                '@media (max-width: 300px)': {
                    flexDirection : "column",
                    height : "auto",
                    gap: 1,
                    py : 1
                },

                ...sx
            }}>
            <Box
                component="img"
                sx={{
                    display: "block",
                    height: "30px",
                }}
                src={getServiceLogoPath()}

                alt={"ext service logo"}
            />
            <Box minWidth={"max-content"}>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        letterSpacing: 0.3,
                        mb: -0.5
                    }}
                >
                    {translate("ui.service_auth_notification_1")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        letterSpacing: 0.3,
                    }}
                >
                    <b style={{ color: "#FF4800" }} >
                        {translate("ui.service_auth_notification_2")}
                    </b>
                    {translate("ui.service_auth_notification_3")}
                </Typography>
            </Box>
        </Box >
    )
};

export default ExtServiceInfo;