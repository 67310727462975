import Router from "./routes";
import ThemeProvider from './theme';

export default function App() {

  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}
