import axios from 'axios';
import { getMessge } from './message';

// ----------------------------------------------------------------------
const baseAuthUrlDev = `https://auth-dev.minibig-api.com/`;
const baseAuthUrl = `https://auth.minibig-api.com/`;
const isProduction = (window.location.origin).includes("auth.minibig.life") ? true : false;
export const HOST_API_AUTH = isProduction ? baseAuthUrl : baseAuthUrlDev;

const axiosInstance = axios.create({
  baseURL: HOST_API_AUTH,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response != null && error.response.data != null) {
      console.log("axios error: ", error.response.data);
      const { code } = error.response.data;
      error.response.data.message = getMessge(code);
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
