import queryString from "query-string";

export default function useIsLight() {
    return { isLight: true };
    const query = queryString.parse(window.location.search) || {};
    const { dark } = query;
    if (dark != undefined) {
        return { isLight: dark != 'Y' };
    }
    return { isLight: true };
}
