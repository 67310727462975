import * as React from 'react';

//? ---- components ----
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
  Link
} from '@mui/material';
import CommonButton from '../components/molecules/CommonButton';
import CustomTextField from '../components/atoms/CustomTextField';
import { Check as CheckIcon } from "@mui/icons-material";
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- utils ----
import { useNavigate } from 'react-router-dom';
import { checkKorean, getParams, regEmail, regPassword } from '../utils/dataUtils';
import { signUpFederate, signUp } from '../apis';
import { API_ERROR_CODE } from '../data/dataCenter';
import queryString from 'query-string';
import useLocales from '../hooks/useLocales';
import { getCallBackUrl } from '../config';

export default function SignUpFed() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { colors } = useTheme();
  const [checked, setChecked] = React.useState([false, false, false]);
  const [emailMsg, setEmailMsg] = React.useState<string | undefined>(undefined);
  const [pwMsg, setPwMsg] = React.useState<string | undefined>(undefined);
  const [pwConfirmMsg, setPwConfirmMsg] = React.useState<string | undefined>(undefined);
  const [agreeMsg, setAgreeMsg] = React.useState<string | undefined>(undefined);

  const [showMarketingAgreement, setShowMarketingAgreement] = React.useState<boolean>(false);
  const [isMarketingShowed, setMarketingShowed] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const params: any = queryString.parse(window.location.search);
  const isFed = params && params.token;

  const isAllChecked = (checked[0] && checked[1] && checked[2]);

  const getUrl = (type: "term" | "user" | "market") => {
    switch (type) {
      case "term": {
        const base = "https://www.clomount.com/policy_";
        return base + (translate('lang_info') == "ko" ? "kor" : "en");
      }
      case "user": {
        const base = "https://www.clomount.com/privacy_";
        return base + (translate('lang_info') == "ko" ? "kor" : "en");
      }
      case "market": {
        const base = "https://www.clomount.com/marketing_";
        return base + (translate('lang_info') == "ko" ? "kor" : "en");
      }
    }
  }

  // ================

  const handleChangeCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked, event.target.checked]);
  };

  const handleChangeCheckTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1], checked[2]]);
  };

  const handleChangeCheckPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked, checked[2]]);
  };

  const handleChangeCheckMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], checked[1], event.target.checked]);
  };

  // ================

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const nativeEvent = event.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter;
    const submitType = submitter?.dataset.submitType;

    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const email: string = data.get('email') as string;
    const password: string = data.get('password') as string;
    const passwordConfirm: string = data.get('passwordConfirm') as string;

    const term = checked[0];
    const privacy = checked[1];
    let marketing = checked[2];

    var valid = true;


    // 이메일 / 패스워드 유효성 검사
    if (email == "" || !regEmail(email as string)) {
      setEmailMsg(translate("ui.signup_error_email_not_valid"));
      valid = false;
    } else {
      setEmailMsg(undefined);
    }

    if (!isFed) {
      if (password == "" || !regPassword(password as string)) {
        setPwMsg(translate("ui.signup_error_password_not_valid"));
        valid = false;
      } else {
        setPwMsg(undefined);
      }

      if (password != passwordConfirm) {
        setPwConfirmMsg(translate("ui.signup_error_password_check_not_valid"));
        valid = false;
      } else {
        setPwConfirmMsg(undefined);
      }
    }

    // 약관 선택 검사
    if (!term || !privacy) {
      setAgreeMsg(translate("ui.signup_term_subtitle"));
      valid = false;
    } else {
      setAgreeMsg(undefined);
    }

    if (!valid) return;


    // 마케팅 권유 표시
    //! 사용 헤제 (API 작업 이후 활성화 )
    // if (checkKorean() && !checked[2] && !isMarketingShowed) {
    //   setShowMarketingAgreement(true);
    //   setMarketingShowed(true);
    //   return;
    // }

    // 마케팅 권유에서 수신 동의 선택 시
    if (submitType == "agree") {
      marketing = true;
      setChecked([checked[0], checked[1], true]);
    }

    setLoading(true);

    if (isFed) { // 외부 서비스 가입
      const { token, provider } = params;
      signUpFederate({
        token,
        provider,
        email,
        marketing,
        locale: 'ko',
      })
        .then((res) => {
          alert(translate("ui.signup_complete"));
          window.location.href = `${getCallBackUrl(provider)}?token=${token}`;
        })
        .catch((error) => {
          console.log('signUp error: ', error)
          setShowMarketingAgreement(false);

          switch (error.code) {
            case API_ERROR_CODE.AUTH_NOT_AUTHENTICATED:
              const stringified = queryString.stringify({
                email,
                token,
                provider,
              });

              navigate(`/signupConfirm?${stringified}`);
              return;
            case API_ERROR_CODE.AUTH_ALREADY_EXiST:
              setEmailMsg(error.message);
              return;
          }
          alert(error.message)
        })
        .finally(() => {
          setLoading(false);
        })
    }
    else { // 이메일 가입
      signUp({
        email,
        pw: password,
        marketing,
        locale: 'ko',
      })
        .then((res) => {
          navigate(`/login`);
          alert(translate("ui.signup_complete"));
        })
        .catch((error) => {
          console.log('signUp error: ', error)
          setShowMarketingAgreement(false);

          switch (error.code) {
            case API_ERROR_CODE.AUTH_NOT_AUTHENTICATED:
              const stringified = queryString.stringify({
                email,
              });

              navigate(`/signupConfirm?${stringified}`);
              return;
            case API_ERROR_CODE.AUTH_ALREADY_EXiST:
              setEmailMsg(error.message);
              return;
          }
          alert(error.message)
        })
        .finally(() => {
          setLoading(false);
        })
    }
  };

  const TermCheckIcon = (props: { sx?: SxProps, active?: boolean, reverse?: boolean }) => {
    const {
      active = false,
      reverse = false,
      sx
    } = props;

    const borderColor = active ? (reverse ? "#FFF" : colors.primary) : colors.deviceIconDisabled;
    const backgroundColor = active ? (reverse ? "#FFF" : colors.primary) : "#FFF";
    const iconColor = reverse ? colors.primary : "#FFF";

    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "18px",
        height: "18px",
        border: `2px solid ${borderColor}`,
        backgroundColor,
        borderRadius: "100%",
        ...sx
      }}>
        {
          active &&
          <SvgIcon component={CheckIcon} sx={{ width: "80%", aspectRatio: 1, color: iconColor }} />
        }
      </Box>
    )
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ position: "relative" }}>
      <CssBaseline />
      <MinibigLogo onClick={() => navigate("/")} />
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={"8px"}>
          <Grid item xs={12}>
            <CustomTextField
              required
              placeholder={translate("ui.signup_email_title")}
              type="email"
              id="email"
              name="email"
              autoComplete="email"
              defaultValue={params.email != undefined ? params.email : undefined}
              helperText={emailMsg}
              error={emailMsg != undefined}
            />
          </Grid>
          {
            !isFed &&
            <>
              <Grid item xs={12}>
                <CustomTextField
                  placeholder={translate("ui.signup_password_title")}
                  required
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  helperText={pwMsg}
                  error={pwMsg != undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  placeholder={translate("ui.signup_password_check_title")}
                  required
                  name="passwordConfirm"
                  type="password"
                  id="passwordConfirm"
                  autoComplete="new-password"
                  helperText={pwConfirmMsg}
                  error={pwConfirmMsg != undefined}
                />
              </Grid>
            </>
          }
          <Grid item xs={12} mt={"12px"}>
            <FormGroup>
              <Box sx={{
                px: 1.8,
                py: 1,
                mb: 1,
                borderRadius: 0.3,
                backgroundColor: isAllChecked ? colors.primary : colors.inputBackground
              }}>
                <FormControlLabel
                  control=
                  {
                    <Checkbox
                      value="allowAll"
                      color="primary"
                      onChange={handleChangeCheckAll}
                      checked={isAllChecked}
                      icon={<TermCheckIcon />}
                      checkedIcon={<TermCheckIcon active reverse />}
                    />
                  }
                  label={translate("ui.signup_term_checkall_title")}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "600",
                      color: isAllChecked ? "#FFF" : "#050fc5",
                    }
                  }}

                />
                {
                  agreeMsg != undefined &&
                  <FormHelperText
                    error>{agreeMsg}</FormHelperText>
                }
              </Box>
              <Box sx={{ px: 1.8 }}>
                <FormControlLabel
                  control={<Checkbox
                    value="term"
                    color="primary"
                    onChange={handleChangeCheckTerm}
                    checked={checked[0]}
                    icon={<TermCheckIcon />}
                    checkedIcon={<TermCheckIcon active />}
                  />}
                  label={
                    <Link
                      sx={{ color: "#000", fontSize: 13, letterSpacing: "-0.05px" }}
                      href={getUrl("term")} variant="body2" target="_blank" underline={"none"}>
                      {translate("ui.signup_term_title_minibig") + " " + translate("ui.signup_term_essential")}
                    </Link>
                  }
                  sx={{
                    "& .MuiCheckbox-root": {
                      py: "7px"
                    }
                  }}
                />
                <FormControlLabel
                  control={<Checkbox
                    value="privacy"
                    color="primary"
                    onChange={handleChangeCheckPrivacy}
                    checked={checked[1]}
                    icon={<TermCheckIcon />}
                    checkedIcon={<TermCheckIcon active />}
                  />}
                  label={
                    <Link
                      sx={{ color: "#000", fontSize: 13, letterSpacing: "-0.05px" }}
                      href={getUrl("user")} variant="body2" target="_blank" underline={"none"} >
                      {translate("ui.signup_term_use_info_title") + " " + translate("ui.signup_term_essential")}
                    </Link>
                  }
                  sx={{
                    "& .MuiCheckbox-root": {
                      py: "7px"
                    }
                  }}
                />
                <FormControlLabel
                  control={<Checkbox
                    value="marketing"
                    color="primary"
                    onChange={handleChangeCheckMarketing}
                    checked={checked[2]}
                    icon={<TermCheckIcon />}
                    checkedIcon={<TermCheckIcon active />}
                  />}
                  sx={{
                    marginRight: 0,
                    mb: 1,
                    "& .MuiCheckbox-root": {
                      py: "7px"
                    }
                  }}
                  label={
                    <Link
                      sx={{ color: "#000", fontSize: 13, letterSpacing: "-0.05px" }}
                      href={getUrl("market")} variant="body2" target="_blank" underline={"none"} >
                      {translate("ui.signup_term_check3_title") + " " + translate("ui.signup_term_optional")}
                    </Link>
                  }
                />
                <Typography
                  variant="body2"
                  fontSize={13}
                  sx={{ whiteSpace: "pre-wrap" }}
                >{translate("ui.signup_term_check3_subtitle")}</Typography>
              </Box>
            </FormGroup>
          </Grid>
        </Grid>
        <CommonButton
          type="submit"
          loading={loading}
          disabled={!checked[0] || !checked[1]}
          sx={{ mt: 10, mb: 2 }}
        >
          {checkKorean() ? translate("ui.button_next") : translate("ui.signup_title")}
        </CommonButton>

        {
          // 마케팅 수신 동의
          showMarketingAgreement &&
          <Box sx={{
            position: "absolute",
            top: 0, bottom: 0, left: 0, right: 0,
            backgroundColor: colors.background
          }}>
            <Box sx={{ textAlign: "center", mb: 6.5, mt: 8 }}>
              <Typography sx={{ fontSize: 26, lineHeight: 1.3, fontWeight: "800" }}>{"잠깐!"}</Typography>
              <Typography sx={{ fontSize: 26, lineHeight: 1.3, fontWeight: "800" }}>{"이대로면 놓치는"}</Typography>
              <Typography sx={{ fontSize: 26, lineHeight: 1.3, fontWeight: "800", color: colors.primary }}>{"혜택이 있어요."}</Typography>
            </Box>

            <Grid container px={3.5} sx={{ mb: 10 }} spacing={1}>
              <Grid item xs={6} sx={{ textAlign: "center" }}>
                <img
                  src={"/icons/coupon.svg"}
                  style={{
                    width: 80,
                    height: 80
                  }} alt="logo"
                />
                <Typography sx={{ fontSize: 15, fontWeight: "800" }}>{"3천원 할인 쿠폰 지급"}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "center" }}>
                <img
                  src={"/icons/coins.svg"}
                  style={{
                    width: 80,
                    height: 80
                  }} alt="logo"
                />
                <Typography sx={{ fontSize: 15, fontWeight: "800" }}>{"변경 예정"}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "center" }}>
                <img
                  src={"/icons/loud_speaker.svg"}
                  style={{
                    width: 80,
                    height: 80
                  }} alt="logo"
                />
                <Typography sx={{ fontSize: 15, fontWeight: "800" }}>{"할인 및 이벤트 소식"}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "center" }}>
                <img
                  src={"/icons/books.svg"}
                  style={{
                    width: 80,
                    height: 80
                  }} alt="logo"
                />
                <Typography sx={{ fontSize: 15, fontWeight: "800" }}>{"스마트 라이프 콘텐츠"}</Typography>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", px: "16px" }}>
              <CommonButton
                data-submit-type={"agree"}
                type="submit"
                loading={loading}
                sx={{ mb: 2 }}
              >
                {"마케팅 수신 동의하기"}
              </CommonButton>
              <Button
                type="submit"
                variant="text"
                sx={{ color: "#000", textAlign: "center", fontSize: 15, letterSpacing: 0 }}
              >
                {"다음에 할게요"}
              </Button>
            </Box>
          </Box>
        }
      </Box>
    </Container >
  );
}