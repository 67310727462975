export const API_ERROR_CODE = {
  INTERNAL_SERVER_ERROR: -1,
  DATA_IS_NULL: -2,
  DB_API_ERROR: -3,
  DATA_IS_INVALID: -4,
  DATA_HAVE_NO_REQUIRED: -5,
  DATA_HAVE_EXCLUSIVE: -6,
  DATA_IS_DELETED: -7,

  PERMISSION_INVALID: -21,
  PERMISSION_NOT_MASTER: -22,
  PERMISSION_CANNOT_MATCH_PLACE: -23,
  PLACE_MUST_HAVE_MASTER: -24,

  TOKEN_EXPIRED: -31,
  TOKEN_INVALID: -32,
  CLIENTID_INVALID: -33,

  LINK_NOT_MATCHED_TYPE: -51,
  LIMIT_MAX_PAIRED_NUM: -52,

  DEVICE_CANNOT_COM: -100,
  DEVICE_NOT_CONNECTED: -101,
  DEVICE_ACTION_TIMEOUT: -102,
  DEVICE_CONTROL_ERROR: -103,
  NOT_MINIBIG_DEVICE: -104,

  USER_PROFILE_NOT_EXiST: -120,
  USER_INVALID: -121,
  USER_NAME_EXiST: -122,

  AUTH_NOT_EXiST: -123,
  AUTH_PW_INVALID: -124,
  AUTH_NOT_AUTHENTICATED: -125,
  AUTH_ALREADY_EXiST: -126,
  AUTH_CODE_INVALID: -127,
  AUTH_CODE_EXPIRED: -128,
  AUTH_ERROR_NONE_PROVIDER: -129,
  AUTH_ERROR_KNOWN: -130,
  AUTH_ERROR_LIMIT: -131,
  AUTH_ERROR_DELETED: -132,
};

export const API_SUCCESS_CODE = {
  API_SUCCESS: 0,
};
