import * as React from 'react';

//? ---- components ----
import {
    Box,
    Container,
    CssBaseline,
    Grid,
    useTheme
} from '@mui/material';
import CommonButton from '../components/molecules/CommonButton';
import CustomTextField from '../components/atoms/CustomTextField';
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- utils ----
import { changePasswordConfirm, resendCode } from '../apis';
import { useNavigate } from 'react-router-dom';
import { regPassword } from '../utils/dataUtils';
import queryString from 'query-string';
import useLocales from '../hooks/useLocales';

export default function ForgotPassWordConfirm() {
    const navigate = useNavigate();
    const { colors } = useTheme();
    const { translate } = useLocales();

    const [codeMsg, setCodeMsg] = React.useState<string | undefined>(undefined);
    const [pwMsg, setPwMsg] = React.useState<string | undefined>(undefined);
    const [pwConfirmMsg, setPwConfirmMsg] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const [resendLoading, setResendLoading] = React.useState(false);

    const handleResend = () => {
        const params: any = queryString.parse(window.location.search);
        const { email } = params;
        if (email == undefined) {
            return;
        }
        setResendLoading(true);
        resendCode({
            email, locale: 'ko', type: "password",
        })
            .then((res) => {
                alert(translate("ui.signupcheck_verfication_resend_toast_message"));
            })
            .catch((error) => {
                console.log('resendCode error: ', error)
                alert(error.message)
            })
            .finally(() => {
                setResendLoading(false);
            })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const params = {
            code: data.get('code'),
            password: data.get('password'),
            passwordConfirm: data.get('passwordConfirm'),
        };
        const queryParams: any = queryString.parse(window.location.search);
        const { email } = queryParams;
        var valid = true;

        if (params.code == "") {
            setCodeMsg(translate("ui.signup_confirm_subtitle"));
            valid = false;
        } else {
            setCodeMsg(undefined);
        }

        if (params.password == "" || !regPassword(params.password as string)) {
            setPwMsg(translate("ui.signup_error_password_not_valid"));
            valid = false;
        } else {
            setPwMsg(undefined);
        }

        if (params.password != params.passwordConfirm) {
            setPwConfirmMsg(translate("ui.signup_error_password_check_not_valid"));
            valid = false;
        } else {
            setPwConfirmMsg(undefined);
        }

        if (!valid || !email) return;
        setLoading(true);
        changePasswordConfirm({
            email,
            pw: params.password as any,
            code: params.code as any,
        })
            .then((res) => {
                navigate(`/login`);
            })
            .catch((error) => {
                console.log('changePasswordConfirm error: ', error)
                alert(error.message)
            })
            .finally(() => {
                setLoading(false);
            })

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MinibigLogo onClick={() => navigate("/")} />
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            fullWidth
                            id="code"
                            placeholder={translate("ui.signupcheck_verfication_code_title")}
                            name="code"
                            helperText={codeMsg}
                            error={codeMsg != undefined}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            fullWidth
                            name="password"
                            placeholder={translate("ui.signup_new_password_title")}
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            helperText={pwMsg}
                            error={pwMsg != undefined}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            fullWidth
                            name="passwordConfirm"
                            placeholder={translate("ui.signup_password_check_title")}
                            type="password"
                            id="passwordConfirm"
                            autoComplete="new-password"
                            helperText={pwConfirmMsg}
                            error={pwConfirmMsg != undefined}
                        />
                    </Grid>
                </Grid>
                <CommonButton
                    type="submit"
                    loading={loading}
                    disabled={loading || resendLoading}
                    sx={{ mt: "20px", mb: "8px" }}
                >
                    {translate("ui.button_confirm")}
                </CommonButton>
                <CommonButton
                    onClick={handleResend}
                    loading={resendLoading}
                    disabled={loading || resendLoading}
                    backgroundColor={colors.inputTitle}
                >
                    {translate("ui.signupcheck_verfication_code_resend_title")}
                </CommonButton>
            </Box>
        </Container>
    );
}