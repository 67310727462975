import * as React from 'react';

//? ---- components ----
import {
    CssBaseline,
    Grid,
    Box,
    Container
} from "@mui/material";
import CommonButton from '../components/molecules/CommonButton';
import CustomTextField from '../components/atoms/CustomTextField';
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- utils ----
import { useNavigate } from 'react-router-dom';
import { regEmail } from '../utils/dataUtils';
import { changePassword } from '../apis';
import queryString from 'query-string';
import useLocales from '../hooks/useLocales';

export default function ForgotPassWord() {
    const navigate = useNavigate();
    const { translate } = useLocales();
    const [emailMsg, setEmailMsg] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const signUpData = {
            email: data.get('email'),
        };
        var valid = true;

        if (signUpData.email == "" || !regEmail(signUpData.email as string)) {
            setEmailMsg(translate("ui.signup_error_email_not_valid"));
            valid = false;
        } else {
            setEmailMsg(undefined);
        }

        if (!valid) return;
        setLoading(true);
        changePassword({
            email: signUpData.email as any,
            locale: 'ko',
        })
            .then((res) => {
                const stringified = queryString.stringify({ email: signUpData.email });
                navigate(`/forgotPwConfirm?${stringified}`);
            })
            .catch((error) => {
                console.log('changePassword error: ', error)
                alert(error.message)
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MinibigLogo onClick={() => navigate("/")} />
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            id="email"
                            placeholder={translate("ui.signup_email_title")}
                            name="email"
                            autoComplete="email"
                            helperText={emailMsg}
                            error={emailMsg != undefined}
                        />
                    </Grid>
                </Grid>
                <CommonButton
                    type="submit"
                    loading={loading}
                    sx={{ mt: "20px" }}>
                    {translate("ui.changepassword_title")}
                </CommonButton>
            </Box>
        </Container>
    );
}