import { base64Encode } from '../utils/dataUtils';
import axios from '../utils/axios';
import { getStorage, removeStorage } from '../utils/storage';

export type respSuccess = {
    data?: any,
    code?: number,
    lastEvaluatedKey?: any
    lastKey?: string
}

export type respFail = {
    error?: string,
    code?: number,
    message?: string;
}

export function signIn({
    authorization,
    redirect_uri,
    response_type,
    client_id,
    state,
    scope,
}: {
    authorization: string;
    redirect_uri: string;
    response_type: string;
    client_id: string;
    state: string;
    scope?: string;
}) {
    axios.defaults.headers.common.Authorization = authorization;
    const params = {
        redirect_uri,
        response_type,
        client_id,
        state,
        scope,
    }
    return axios.get('/oauth2/authorizeResponse', { params });
}

export function signInFederate({
    token,
    provider,
    redirect_uri,
    response_type,
    client_id,
    state,
    scope,
}: {
    token: string;
    provider: string;
    redirect_uri: string;
    response_type: string;
    client_id: string;
    state: string;
    scope?: string;
}) {
    const authorization = base64Encode(JSON.stringify({ token, provider, type: 'federate' }))
    axios.defaults.headers.common.Authorization = authorization;
    const params = {
        redirect_uri,
        response_type,
        client_id,
        state,
        scope,
    }
    return axios.get('/oauth2/authorizeResponse', { params });
}

export const signUp = (params: {
    email: string;
    pw: string;
    locale: string;
    marketing: boolean;
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const {
        email, pw, locale, marketing
    } = params;
    const authorization = base64Encode(JSON.stringify({ email: email.toLowerCase(), pw, marketing }));
    const payload = {
        authorization,
        clientId: client_id,
        locale,
        marketing
    }
    return axios.post<respSuccess | respFail>("/local/signUp", payload);
};

export const signUpFederate = (params: {
    email: string;
    token: string;
    provider: string;
    locale: string;
    marketing: boolean;
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const { token, provider, email, locale, marketing } = params;
    const payload = {
        token,
        provider,
        email: email.toLowerCase(),
        clientId: client_id,
        locale,
        marketing,
    }
    return axios.post<respSuccess | respFail>("/federate/signUp", payload);
};

export const signUpConfirm = (params: {
    email: string;
    code: string;
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const {
        email, code
    } = params;
    const payload = {
        email,
        code,
        clientId: client_id,
    };
    return axios.post<respSuccess | respFail>("/local/signUpConfirm", payload);
};

export const signUpConfirmFederate = (params: {
    token: string,
    provider: string,
    code: string
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const { token, provider, code } = params;
    const payload = {
        token,
        code,
        provider,
        clientId: client_id,
    };
    return axios.post<respSuccess | respFail>("/federate/signUpConfirm", payload);
};

export const resendCode = (params: {
    email: string,
    locale: string,
    type: 'signup' | 'password'
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const { email, locale, type } = params;
    return axios.post<respSuccess | respFail>("/local/resendCode", { email: email.toLowerCase(), locale: locale, clientId: client_id, type });
};

export const resendCodeFederate = (params: {
    token: string,
    provider: string,
    locale: String
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const { token, provider, locale } = params;
    const payload = {
        token,
        provider,
        locale,
        clientId: client_id
    };
    return axios.post<respSuccess | respFail>("/federate/resendCode", payload);
};

export const changePassword = ({ email, locale }: {
    email: string,
    locale: string
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    return axios.post<respSuccess | respFail>("/local/changePassword", { email: email.toLowerCase(), clientId: client_id, locale: locale });
};

export const changePasswordConfirm = ({ email, pw, code }: {
    email: string,
    pw: string,
    code: string
}) => {
    const authData = getStorage('params')!;
    const {
        client_id,
    } = JSON.parse(authData);

    const authorization = base64Encode(JSON.stringify({ email: email.toLowerCase(), pw }));
    return axios.post<respSuccess | respFail>("/local/changePasswordConfirm", { authorization, code, clientId: client_id });
};