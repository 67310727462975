import {
    API_ERROR_CODE,
} from '../data/dataCenter';
import { translate } from './i18n';

export function getMessge(code: number) {
    var msg = translate("action.INTERNAL_SERVER_ERROR");
    switch (code) {
        /*
        API ERROR MESSAGE
        */
        case API_ERROR_CODE.INTERNAL_SERVER_ERROR:
        case API_ERROR_CODE.DB_API_ERROR:
            msg = translate('action.INTERNAL_SERVER_ERROR')
            break;
        case API_ERROR_CODE.DATA_IS_NULL:
        case API_ERROR_CODE.DATA_IS_INVALID:
        case API_ERROR_CODE.DATA_IS_DELETED:
        case API_ERROR_CODE.DATA_HAVE_NO_REQUIRED:
        case API_ERROR_CODE.DATA_HAVE_EXCLUSIVE:
            msg = translate('action.DATA_IS_INVALID')
            break;

        case API_ERROR_CODE.PERMISSION_INVALID:
        case API_ERROR_CODE.PERMISSION_CANNOT_MATCH_PLACE:
            msg = translate('action.PERMISSION_INVALID')
            break;
        case API_ERROR_CODE.PERMISSION_NOT_MASTER:
            msg = translate('action.PERMISSION_NOT_MASTER')
            break;

        case API_ERROR_CODE.TOKEN_EXPIRED:
            msg = translate('action.api_master_poermission_failed')
            break;
        case API_ERROR_CODE.TOKEN_INVALID:
            msg = translate('action.api_master_poermission_failed')
            break;
        case API_ERROR_CODE.CLIENTID_INVALID:
            msg = translate('action.api_master_poermission_failed')
            break;

        case API_ERROR_CODE.LIMIT_MAX_PAIRED_NUM:
            msg = translate('action.LIMIT_MAX_PAIRED_NUM')
            break;
        case API_ERROR_CODE.DEVICE_CANNOT_COM:
            msg = translate('action.device_can_not_connect')
            break;
        case API_ERROR_CODE.DEVICE_NOT_CONNECTED:
            msg = translate('action.device_can_not_connect')
            break;
        case API_ERROR_CODE.DEVICE_ACTION_TIMEOUT:
            msg = translate('action.device_can_not_connect')
            break;
        case API_ERROR_CODE.DEVICE_CONTROL_ERROR:
            msg = translate('action.device_can_not_connect')
            break;

        case API_ERROR_CODE.AUTH_NOT_EXiST:
            msg = translate('action.AUTH_NOT_EXiST')
            break;
        case API_ERROR_CODE.AUTH_PW_INVALID:
            msg = translate('action.AUTH_PW_INVALID')
            break;
        case API_ERROR_CODE.AUTH_NOT_AUTHENTICATED:
            msg = translate('action.AUTH_NOT_AUTHENTICATED')
            break;
        case API_ERROR_CODE.AUTH_ALREADY_EXiST:
            msg = translate('action.AUTH_ALREADY_EXiST')
            break;
        case API_ERROR_CODE.AUTH_CODE_INVALID:
            msg = translate('action.AUTH_CODE_INVALID')
            break;
        case API_ERROR_CODE.AUTH_CODE_EXPIRED:
            msg = translate('action.AUTH_CODE_EXPIRED')
            break;
        case API_ERROR_CODE.AUTH_ERROR_NONE_PROVIDER:
            msg = translate('action.AUTH_ERROR_NONE_PROVIDER')
            break;
        case API_ERROR_CODE.AUTH_ERROR_DELETED:
            msg = translate('action.AUTH_ERROR_DELETED')
            break;
        case API_ERROR_CODE.AUTH_ERROR_DELETED:
            msg = "탈퇴한 회원입니다. 다시 회원가입을 시도해주세요.";
            break;
        case API_ERROR_CODE.USER_NAME_EXiST:
            msg = translate('action.USER_NAME_EXiST')
            break;
    }
    msg = `${msg}, code: ${code}`;
    return msg
}