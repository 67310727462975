import { createSlice } from '@reduxjs/toolkit';
import { OAuthParamType } from '../../@types/models/oauth';

const initialState: OAuthParamType = {

};

const slice = createSlice({
    name: 'oauth',
    initialState,
    reducers: {
        setOAuth(state, action) {
            const data: OAuthParamType = action.payload;
            state.redirect_uri = data.redirect_uri;
            state.response_type = data.response_type;
            state.client_id = data.client_id;
            state.state = data.state;
            state.scope = data.scope;
            
        },
    }
})

// Reducer
export default slice.reducer;

// Actions
export const { setOAuth } = slice.actions;