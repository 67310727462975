import { Box, SxProps } from "@mui/material";

type Props = {
    title: string;
    bgColor?: string;
    sx?: SxProps;
}

const LoginFedSpeechBubble = (props: Props) => {
    const { title, bgColor = "#FF4800", sx } = props;

    return (
        <Box
            sx={{
                width: "min-content",
                py: "5px",
                px: "11px",
                backgroundColor: bgColor,
                color: "#FFF",
                fontSize: "12px",
                lineHeight: "14px",
                whiteSpace: "nowrap",
                position: "relative",
                borderRadius: 0.2,
                fontWeight : "600",

                "&::after": {
                    content: '""',
                    display: "block",
                    position: "absolute",

                    borderTop: "4px solid transparent",
                    borderBottom: "4px solid transparent",
                    borderLeft: `6px solid ${bgColor}`,

                    top: "calc(100% + 1px)",
                    left: "50%",
                    transform: "rotate(90deg) translateX(-50%)"
                },
                ...sx
            }}
        >
            {title}
        </Box>
    )
};

export default LoginFedSpeechBubble;