import { useTranslation } from 'react-i18next';
// @mui
import { enUS, jaJP, koKR, zhTW } from '@mui/material/locale';
import { } from '../utils/i18n';

// ----------------------------------------------------------------------

// https://www.svgrepo.com/collection/decathlon-payment-vectors/4

const LANGS = [
    {
        label: 'Korea',
        value: 'ko',
        systemValue: koKR,
        icon: 'icons/flag-kr-svgrepo-com.svg',
    },
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: 'icons/flag-um-svgrepo-com.svg',
    },
    {
        label: 'Japanese',
        value: 'ja',
        systemValue: jaJP,
        icon: 'icons/flag-jp-svgrepo-com.svg',
    },
    {
        label: 'Chinese',
        value: 'zh',
        systemValue: zhTW,
        icon: 'icons/flag-cn-svgrepo-com.svg',
    }
];

export default function useLocales() {
    const { i18n, t: translate } = useTranslation();
    const langStorage = localStorage.getItem('i18nextLng');
    const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

    const handleChangeLanguage = (newlang: string) => {
        localStorage.setItem('i18nextLng', newlang);
        i18n.changeLanguage(newlang);
    };

    return {
        onChangeLang: handleChangeLanguage,
        translate,
        currentLang: currentLang || LANGS[0],
        allLang: LANGS
    };
}
