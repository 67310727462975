export const getCallBackUrl = (provider: string) => {
    return `${window.location.origin}/${provider}/callback`;
}

export const KAKAO_PARAMS = {
    JS_KEY: "c95256c9b543546f491568b1419f6c67",
    CLIENT_ID: "c95256c9b543546f491568b1419f6c67",
    REDIRECT_URL: getCallBackUrl('kakao'),
}

export const GOOGLE_PARAMS = {
    CLIENT_ID: '423558026896-2ven3otv0o00cirm2sr95r4p237rl7fh.apps.googleusercontent.com',
    REDIRECT_URL: getCallBackUrl('google'),
}

export const APPLE_PARAMS = {
    CLIENT_ID: 'com.clomount.minibigwebauth',
    REDIRECT_URL: getCallBackUrl('apple'),
}

export const EMAIL_PARAMS = {
    REDIRECT_URL: getCallBackUrl('email'),
}