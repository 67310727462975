import { useMemo } from 'react';

//? ---- components ----
import {
    CssBaseline,
    Box,
    Stack
} from '@mui/material';
import LoginButton from '../components/organisms/LoginButton';
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- types ----
import { AuthProviderType } from '../@types/models/oauth';

//? ---- utils ----
import { APPLE_PARAMS, GOOGLE_PARAMS, KAKAO_PARAMS } from '../config';
import { useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useLocales from '../hooks/useLocales';
import LoginFedSpeechBubble from '../components/organisms/LoginFedSpeechBubble';

export default function LoginFed() {
    const navigate = useNavigate();
    const { currentLang } = useLocales();
    const { from }: any = useParams();

    const kakaoBenefitImgUrl = "https://plait-asset-resouces.s3.ap-northeast-2.amazonaws.com/img/etc/store_kakao_plus_benfit_notification.png";
    const isFromStore = from && from == "store" && currentLang.value == "ko";

    const currentOS = useMemo(() => {
        const ua = navigator.userAgent;
        let os = "web";

        if ((ua.indexOf("iPad") != -1) || (ua.indexOf("iPhone") != -1) || (ua.indexOf("iPod") != -1 || ua.match(/Mac|PPC/))) {
            os = "ios";
        } else if (ua.indexOf("Android") != -1) {
            os = "aos";
        } else {
            os = "web"
        }

        return os;
    }, []);

    const getButtonLayout: () => { top: AuthProviderType[], bottom: AuthProviderType[] } = () => {
        switch (currentOS) {
            case "ios": {
                return {
                    top: ["kakao"],
                    bottom: ["apple", "google", "email"]
                }
            }
            case "aos": {
                return {
                    top: ["kakao"],
                    bottom: ["google", "email"]
                }
            }
            default: {
                return {
                    top: ["kakao"],
                    bottom: ["google", "apple", "email"]
                }
            }
        }
    };

    const handleLogin = (type: AuthProviderType) => {
        switch (type) {
            case 'kakao': {
                var kakaoThroughError = false;
                if (from == "clova" || from == "gh") {
                    kakaoThroughError = true;
                }

                const sdk: any = (window as any).Kakao;
                if (!sdk.isInitialized()) {
                    sdk.init(KAKAO_PARAMS.JS_KEY);
                }
                sdk.Auth.authorize({
                    redirectUri: KAKAO_PARAMS.REDIRECT_URL,
                    throughTalk: !kakaoThroughError,
                });
                break;
            }
            case 'google': {
                const sdk = (window as any).gapi;
                sdk.load('auth2', function () {
                    const gauth: any = sdk.auth2.init({
                        client_id: GOOGLE_PARAMS.CLIENT_ID,
                        redirect_uri: GOOGLE_PARAMS.REDIRECT_URL,
                        ux_mode: "redirect",
                    });
                    gauth.signIn();
                });
                break;
            }
            case 'apple': {
                const stringified = queryString.stringify({
                    response_type: "code id_token",
                    response_mode: "fragment",
                    client_id: APPLE_PARAMS.CLIENT_ID,
                    redirect_uri: APPLE_PARAMS.REDIRECT_URL,
                    state: "state",
                    nonce: "nonce",
                });
                const url = `https://appleid.apple.com/auth/authorize?${stringified}`;
                window.location.href = url;
                break;
            }
            default:
                navigate(`/login${window.location.search}`);
                break;
        }
    }

    return (
        <Stack component={"main"}
            sx={{
                position: "relative",
                top: isFromStore ? "40%" : "35%",
                transform: "translateY(-50%)"
            }}
        >
            <CssBaseline />
            <MinibigLogo onClick={() => navigate("/")} sx={{ mb: isFromStore ? 2 : 10 }} />
            {
                // 스토어 통해 이동시 이미지 표시
                isFromStore &&
                <Box
                    component={"img"}
                    src={kakaoBenefitImgUrl}
                    sx={{ mt: 3, mb: 4.5, width: "80%", alignSelf: "center" }}
                />
            }
            {
                currentLang.value == "ko" &&
                <LoginFedSpeechBubble title={"3초만에 빠른 회원가입"} sx={{ mb: 1.5, alignSelf: "center" }} />
            }
            {
                getButtonLayout().top.length > 0 &&
                getButtonLayout().top.map((provider) => {
                    return (
                        <LoginButton
                            key={provider}
                            provider={provider}
                            onClick={() => handleLogin(provider)}
                        />
                    )
                })
            }
            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} gap={2} mt={2}>
                {
                    getButtonLayout().bottom.length > 0 &&
                    getButtonLayout().bottom.map((provider) => {
                        return (
                            <LoginButton
                                key={provider}
                                provider={provider}
                                onClick={() => handleLogin(provider)}
                                isCircleButton={true}
                            />
                        )
                    })
                }
            </Box>
        </Stack>
    );
}

