import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import useIsLight from '../hooks/useIsLight';
import palette from './palette';
import { colorsDark, colorsLight } from '../constants/colors';
import { colorType } from '../@types/theme';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

declare module '@mui/material/styles' {
  interface Theme {
    colors: colorType;
  }
  interface ThemeOptions {
    colors?: colorType;
  }
}

export default function ThemeProvider({ children }: Props) {
  const { isLight } = useIsLight();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      colors: isLight ? colorsLight : colorsDark,
      shape: { borderRadius: 8 },
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
