import React from "react";

//? ---- components ----
import { Button, SxProps, useTheme } from "@mui/material";

//? ---- utils ----
import useLocales from "../../hooks/useLocales";

type Props = {
    provider: string;
    sx?: SxProps;
    onClick: () => void;
    isCircleButton?: boolean;
}

const LoginButton = (props: Props) => {
    const { translate } = useLocales();
    const { colors } = useTheme();

    const { provider, isCircleButton, sx, onClick } = props;

    const getDataByProvider = () => {
        switch (provider) {
            case "kakao": {
                return {
                    title: translate("ui.auth_social_kakao"),
                    iconSrc: "/img/kakao.png",
                    bgColor: "#FEE500",
                    titleColor: "#000000",
                }
            }
            case "google": {
                return {
                    title: translate("ui.auth_social_google"),
                    iconSrc: "/img/google.png",
                    bgColor: "#f5f5f5",
                    titleColor: "#000000"
                }
            }
            case "apple": {
                return {
                    title: translate("ui.auth_social_apple"),
                    iconSrc: "/img/apple_white.png",
                    bgColor: "#000000",
                    titleColor: "#FFFFFF"
                }
            }
            case "email": {
                return {
                    title: translate("ui.auth_social_email"),
                    iconSrc: "/img/email.png",
                    bgColor: colors.primary,
                    titleColor: "#FFFFFF"
                }
            }
            default: {
                return {
                    title: translate("ui.auth_social_email"),
                    iconSrc: "/img/email.png",
                    bgColor: colors.primary,
                    titleColor: "#FFFFFF"
                }
            }
        }
    }

    const { title, iconSrc, bgColor, titleColor } = getDataByProvider();

    return (
        <>
            {
                isCircleButton ?
                    <Button
                        onClick={onClick}
                        sx={{
                            margin: 0,
                            padding: 0,
                            height: "45px",
                            width: "45px",

                            borderRadius: "50%",
                            boxShadow: "none",
                            backgroundColor: bgColor,

                            minWidth: 0,
                            "&:hover": {
                                backgroundColor: bgColor,

                                opacity: 0.9,
                                boxShadow: "none"
                            },
                            ...sx
                        }}
                    >
                        <img
                            src={iconSrc}
                            style={{
                                alignSelf: "center",
                                width: 21,
                            }}
                            alt=''
                        />
                    </Button>
                    :
                    <Button
                        onClick={onClick}
                        fullWidth
                        variant="contained"
                        sx={{
                            height: "56px",
                            lineHeight: "56px",
                            mb: "8px",

                            borderRadius: 0.3,
                            boxShadow: "none",
                            backgroundColor: bgColor,

                            // fontSize: "14.5px",
                            fontSize: "16px",
                            fontWeight : "600",
                            color: titleColor,
                            letterSpacing: 0,
                            "&:hover": {
                                backgroundColor: bgColor,
                                opacity: 0.9,
                                boxShadow: "none"
                            },
                            "&:active": {
                                boxShadow: "none"
                            },
                            ...sx
                        }}
                        startIcon={
                            <img
                                src={iconSrc}
                                style={{
                                    alignSelf: "center",
                                    width: 21,
                                }}
                                alt=''
                            />}
                    >
                        {title}
                    </Button>
            }
        </>
    )
}

export default LoginButton;