import React from "react";

//? ---- components ----
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

//? ---- utils ----
import { useTheme } from "@mui/material";

interface props extends LoadingButtonProps {
    backgroundColor?: string;
};

const CommonButton = (props: props) => {
    const { colors } = useTheme();
    const {
        backgroundColor = colors.primary
    } = props;

    return (
        <LoadingButton
            fullWidth
            variant="contained"

            {...props}

            sx={{
                boxShadow: "none",
                height: "56px",
                fontSize: "16px",
                fontWeight: "600",
                background: backgroundColor,
                borderRadius: 0.3,
                letterSpacing: 0,
                "&:hover": {
                    backgroundColor: backgroundColor,
                    opacity: 0.9,
                    boxShadow: "none",
                },
                "&.Mui-disabled": {
                    background: backgroundColor,
                    color: "#FFFFFF70"
                },
                "&.MuiLoadingButton-loading": {
                    color: "transparent"
                },
                ...props.sx
            }}
        >
            {props.children}
        </LoadingButton>
    )
};

export default CommonButton;