import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import langs from "../data/langs.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translations: langs.languages.en },
            ko: { translations: langs.languages.ko },
            ja: { translations: langs.languages.ja },
            zh: { translations: langs.languages.zh },
        },
        lng: localStorage.getItem('i18nextLng') || 'ko',
        fallbackLng: 'ko',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        }
    });

const translate = (key: string) => {
    return i18n.t(key);
}

export {
    i18n, translate
};