import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

const CustomTextField = (props: TextFieldProps) => {

    return (
        <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}

            {...props}

            sx={{
                "& .MuiOutlinedInput-root": {
                    borderRadius: "2px",
                    color: "#1A1A1A",
                    fontWeight: "500",
                    fontSize: "14px",
                },
                "& ::placeholder": {
                    color: "#1A1A1A",
                    fontWeight: "500",
                    fontSize: "14px",
                    opacity: 0.7
                },
                "& .MuiFormHelperText-root": {
                    marginLeft: 0,
                    fontWeight: 500
                },
                ...props.sx
            }}
        />
    )
}

export default CustomTextField;