import * as React from 'react';

//? ---- components ----
import { CssBaseline, Typography, useTheme, Container, Box, Grid } from '@mui/material';
import CommonButton from '../components/molecules/CommonButton';
import CustomTextField from '../components/atoms/CustomTextField';
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- utils ----
import { useNavigate } from 'react-router-dom';
import { signUpConfirmFederate, resendCodeFederate, resendCode, signUpConfirm, signInFederate } from '../apis';
import queryString from 'query-string';
import useLocales from '../hooks/useLocales';
import { getCallBackUrl } from '../config';

export default function SignUp() {
    const { translate } = useLocales();
    const { colors } = useTheme();
    const navigate = useNavigate();

    const [codeMsg, setCodeMsg] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const [resendLoading, setResendLoading] = React.useState(false);

    const params: any = queryString.parse(window.location.search);
    const isFed = !!(params && params.token);

    const handleResend = () => {
        setResendLoading(true);
        if (isFed) {
            resendCodeFederate({
                token: params.token,
                provider: params.provider,
                locale: 'ko'
            })
                .then((res) => {
                    alert(translate("ui.signupcheck_verfication_resend_toast_message"));
                })
                .catch((error) => {
                    console.log('resendCode error: ', error)
                    alert(error.message)
                })
                .finally(() => {
                    setResendLoading(false);
                })
        }
        else {
            const { email } = params;
            if (email == undefined) {
                return;
            }
            resendCode({
                email, locale: 'ko', type: "signup"
            })
                .then((res) => {
                    alert(translate("ui.signupcheck_verfication_resend_toast_message"));
                })
                .catch((error) => {
                    console.log('resendCode error: ', error)
                    alert(error.message)
                })
                .finally(() => {
                    setResendLoading(false);
                })
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const code: string = data.get('code') as string;
        if (code == "") {
            setCodeMsg(translate("ui.signup_confirm_subtitle"));
            return;
        } else {
            setCodeMsg(undefined);
        }

        setLoading(true);
        if (isFed) {
            const {
                token, provider
            } = params;

            signUpConfirmFederate({
                token,
                provider,
                code: code,
            })
                .then((res) => {
                    window.location.href = `${getCallBackUrl(provider)}?token=${token}`;;
                    alert(translate("ui.signup_complete"));
                })
                .catch((error) => {
                    console.log('signUpConfirm error: ', error)
                    alert(error.message)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else {
            const { email } = params;
            if (email == undefined) {
                return;
            }
            signUpConfirm({
                email,
                code,
            })
                .then((res) => {
                    navigate(`/login`);
                    alert(translate("ui.signup_complete"));
                })
                .catch((error) => {
                    console.log('signUpConfirm error: ', error)
                    alert(error.message)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MinibigLogo onClick={() => navigate("/")} />
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

                <Typography
                    variant="subtitle1"
                    gutterBottom
                    align="center"
                    sx={{
                        mb: 2
                    }}
                >
                    {translate("ui.signup_confirm_subtitle")}
                </Typography>

                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <CustomTextField
                            required
                            id="code"
                            placeholder={translate("ui.signupcheck_verfication_code_title")}
                            name="code"
                            helperText={codeMsg}
                            error={codeMsg != undefined}
                        />
                    </Grid>

                </Grid>
                <CommonButton
                    type="submit"
                    loading={loading}
                    disabled={loading || resendLoading}
                    sx={{ mt: "20px", mb: "8px" }}
                >
                    {translate("ui.button_confirm")}
                </CommonButton>
                <CommonButton
                    onClick={handleResend}
                    loading={resendLoading}
                    disabled={loading || resendLoading}
                    backgroundColor={colors.inputTitle}
                >
                    {translate("ui.signupcheck_verfication_code_resend_title")}
                </CommonButton>
            </Box>
        </Container>
    );
}