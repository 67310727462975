import * as React from 'react';

//? ---- components ----
import {
    CssBaseline,
    Box,
    Container,
    Grid,
    Link,
    useTheme,
} from '@mui/material';
import CommonButton from '../components/molecules/CommonButton';
import CustomTextField from '../components/atoms/CustomTextField';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MinibigLogo from '../components/atoms/MinibigLogo';

//? ---- utils ----
import { useNavigate } from 'react-router-dom';
import useLocales from '../hooks/useLocales';
import { base64Encode } from '../utils/dataUtils';

export default function Login() {
    const navigate = useNavigate();
    const { translate } = useLocales();
    const { colors } = useTheme();

    const [isPasswordVisible, setPasswordVisible] = React.useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const id = data.get('email') as string
        const pw = data.get('password') as string
        if (id == null || pw == null) {
            return;
        }

        const authorization = base64Encode(JSON.stringify({ email: id, pw, type: 'email' }));
        navigate(`/email/callback?authorization=${authorization}&email=${id}`)
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MinibigLogo onClick={() => navigate("/")} />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <CustomTextField
                    margin="normal"
                    required
                    id="email"
                    placeholder={translate("ui.signup_email_title")}
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    sx={{
                        mb: "8px"
                    }}
                />
                <CustomTextField
                    required
                    fullWidth
                    name="password"
                    placeholder={translate("ui.signup_password_title")}
                    type={isPasswordVisible ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                onClick={() => setPasswordVisible(prev => !prev)} >
                                {
                                    isPasswordVisible ?
                                        <Visibility sx={{ width: 20, height: 20, color: colors.deviceIconColor, cursor: "pointer" }} />
                                        : <VisibilityOff sx={{ width: 20, height: 20, color: colors.deviceIconDisabled, cursor: "pointer" }} />
                                }
                            </Box>
                        ),
                    }}
                />

                <CommonButton
                    type="submit"
                    sx={{ mt: "20px", mb: "8px" }}
                >
                    {translate("ui.signin_login_title")}
                </CommonButton>

                <Grid container>
                    <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Link href={`/forgotPw${window.location.search}`} variant="body2" sx={{ textDecoration: "none", fontWeight: "500" }}>
                                {translate("ui.signin_findpassword_title")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href={`/signup${window.location.search}`} variant="body2" sx={{ textDecoration: "none", fontWeight: "500" }}>
                                {translate("ui.signup_title")}
                            </Link>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </Container >
    );
}

