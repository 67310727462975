import { useRoutes } from 'react-router-dom';
import AuthLayout from "../layout/AuthLayout"
import LoginFed from "../pages/LoginFed";

import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import SignUpConfirm from "../pages/SignUpConfirm";
import ForgotPassWord from "../pages/ForgotPassWord";
import ForgotPassWordConfirm from "../pages/ForgotPassWordConfirm";
import Callback from "../pages/Callback";

export default function Router() {

  return useRoutes([
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { element: <LoginFed />, index: true },
        {
          path: '/:from', element: <LoginFed />
        },
        {
          path: 'loginFed', element: <LoginFed />
        },
        {
          path: 'loginFed/:from', element: <LoginFed />
        },
        {
          path: 'login', element: <Login />
        },
        {
          path: 'signup', element: <SignUp />
        },
        {
          path: 'signupConfirm', element: <SignUpConfirm />
        },
        {
          path: 'forgotPw', element: <ForgotPassWord />
        },
        {
          path: 'forgotPwConfirm', element: <ForgotPassWordConfirm />
        },

        {
          path: ':provider/callback', element: <Callback />
        },

      ],
    }
  ])
}