import { Box, SxProps } from "@mui/material";

type Props = {
    sx?: SxProps;
    onClick: () => void;
}

const MinibigLogo = (props: Props) => {
    const { sx, onClick } = props;

    return (
        <Box display={"flex"} justifyContent={"center"}>
            <Box
                component={"img"}
                src={"/logo/logo_text.png"}
                sx={{
                    width: "118px",
                    mb: 5,
                    ...sx
                }}
                onClick={onClick}
                alt="logo">
            </Box>
        </Box>
    )
};

export default MinibigLogo;